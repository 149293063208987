import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm `}<a parentName="h1" {...{
        "href": "https://i.overio.space"
      }}>{`Pero`}</a></h1>
    <p>{`A guy that thinks code and product in startup.`}<br parentName="p"></br>{`
`}{`Welcome to join me to build yourself mind place.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      