import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Principle`}</h2>
    <ProjectCard title="Why-How-What" link="" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This is the first principle for me to think about problems.  
  I usually comb them according to the logical chain of Why-How-What.   
  From essence to appearance.
    </ProjectCard>
    <ProjectCard title="First things first" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Rule No.2: first things first, that's it.
    </ProjectCard>
    <ProjectCard title="do things like a robot" link="" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Rule No.3: do one thing, do it right, then another thing.
    </ProjectCard>
    <ProjectCard title="Be aware of your emotion" link="" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Emotions don't lie. Gain self-awareness through emotion reflection.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      